.header {
  height: 70px;
  width: 100%;
  background-color: rgb(48, 7, 6);
  display: flex;
  justify-content: space-between;
  position: fixed;
}
.hamburger {
  height: 28px;
  width: 28px;
  margin: 10px;
  padding: 10px;
  border: 1px solid rgb(125, 125, 125);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.hamburger:hover {
  background-color: rgb(125, 125, 125);
}
.hamburger:active {
  background-color: rgb(0, 0, 0);
}
.hamburger-line {
  height: 2px;
  background-color: rgb(255, 255, 255);
}
.title {
  text-align: center;
}
.title h1 {
  margin: 0;
  color: rgb(255, 255, 255);
  font-weight: 300;
}
.title h3 {
  margin: 0;
  color: rgb(125, 125, 125);
  font-weight: 500;
}
.darkmode-btn-wrapper {
  height: 70px;
  width: 50px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.darkmode-btn-wrapper h5 {
  margin: 5px 0;
  color: rgb(173, 216, 230);
  text-align: center;
}
.darkmode-btn-oval {
  height: 14px;
  width: 50px;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  position: relative;
}
.darkmode-btn-oval-on {
  background-color: rgb(173, 216, 230);
}
.darkmode-btn-label {
  margin: 0;
  padding-left: 15px;
  text-align: center;
  line-height: 14px;
}
.darkmode-btn-label-on {
  padding-left: 0;
  padding-right: 15px;
}
.darkmode-btn-circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgb(173, 216, 230);
  position: absolute;
  top: -3px;
  left: -3px;
}
.darkmode-btn-circle-right {
  left: 33px;
}