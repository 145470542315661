.app {
  display: flex;
  flex-direction: column;
  position: relative;
}
.main {
  min-height: calc(100vh - 105px);
  margin: 70px 0 35px 0;
  padding: 0 10px;
  text-align: center;
}
.main-dark {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}