.resume {
  padding-bottom: 10px;
}
.resume h2 {
  font-weight: 300;
}
.resume a img {
  width: 100%;
  max-width: 873px;
  box-shadow: 0 5px 10px 2px rgb(125, 125, 125);
}