.drawer {
  z-index: 1;
  width: 50%;
  max-width: 250px;
  height: 100%;
  background-color: rgb(200, 200, 200);
  position: fixed;
  left: -50%;
}
.drawer-open {
  left: 0;
}
.drawer img {
  margin-left: calc(100% - 50px);
}
.drawer img:hover {
  background-color: rgb(125, 125, 125);
}
.drawer div {
  display: flex;
  flex-direction: column;
}
.drawer div a {
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  margin: 0 5px;
  font-size: 20px;
  color: rgb(48, 7, 6);
  border-top: 1px solid rgb(125, 125, 125);
}
.drawer div a:hover {
  background-color: rgb(125, 125, 125);
}
.drawer div a:active {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
@media only screen and (min-width: 530px) {
  .drawer {
    width: 25%;
  }
}