.home #headshot {
  display: block;
  margin: 20px auto;
  max-width: 200px;
  max-height: 200px;
  border: 2px solid rgb(48, 7, 6);
  border-radius: 50%;
}
.home h1, .home h3 {
  font-weight: 300;
}
.home #logo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.home #logo-container img {
  margin: 10px;
}
@media only screen and (min-width: 320px) {
  .home #headshot {
    width: 60%;
    height: auto;
  }
}