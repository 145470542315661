.projects {
  max-width: 500px;
  padding: 10px 0;
  margin: 0 auto;
}
.projects h2{
  margin: 10px 0;  
  font-weight: 300;
}
.projects .bottom-extra {
  font-style: italic;
  margin: 35px 0;
}
.projects .project {
  margin-bottom: 20px;
}
.projects .project .project-header {
  display: flex;
  justify-content: space-between;
}
.projects .project .project-header h3 {
  margin: 0;
  line-height: 30px;
  font-weight: 600;
}
.projects .project .project-header a {
  height: 30px;
}
.projects .project .project-header a img {
  height: 30px;
}
.projects .project .project-header a img:hover {
  background-color: rgb(125, 125, 125);
}
.projects .project a {
  height: 100%;
}
.projects .project a .project-img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 5px 10px 2px rgb(125, 125, 125);
}