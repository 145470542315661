.footer {
  height: 35px;
  width: 100%;
  background-color: rgb(48, 7, 6);
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
}
.footer a {
  display: flex;
  text-decoration: none;
}
.footer a:hover {
  background-color: rgb(125, 125, 125);
}
.footer a:active {
  background-color: rgb(0, 0, 0);
}
.footer a p {
  margin: 0;
  padding-right: 5px;
  line-height: 35px;
  font-weight: 500;
  color: rgb(255, 255, 255);
}